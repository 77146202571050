import { INVALID_MOVE } from "boardgame.io/core";
import { ALL_TILES, TAVERN_TILE_ID, TileSpec } from "./Tiles";
import {
  AiEnumerate,
  Ctx,
  FnContext,
  PlayerID,
  Game,
  type State,
} from "boardgame.io";

type Cell = {
  tileSpecId: string;
  revealed?: boolean;

  // between 0 and 5, inclusive
  // rotation direction is clockwise
  rotation: number;
};

type Cells = {
  // the key is a string representation of the coordinates: "q,r,s"
  [key: string]: Cell;
};

export type Props = {
  cells: Cells;
};

type MoveParams = FnContext<Props, {}> & {
  playerID: PlayerID;
};

export const TaverniaGame: Game = {
  name: "tavernia",
  minPlayers: 2,
  maxPlayers: 2,

  setup: () => {
    let props: Props = {
      cells: {
        "0,0,0": { tileSpecId: TAVERN_TILE_ID, rotation: 0, revealed: true },
      },
    };
    return props;
  },

  turn: {},

  moves: {
    clickCell: ({ G, playerID, events }: MoveParams, cellIndex: number) => {
      // TODO: implement rules of tavernia
      return INVALID_MOVE;

      events.endTurn();
    },
  },

  endIf: ({ G, ctx }: FnContext<Props, {}>) => {
    if (IsVictory(G.cells)) {
      return { winner: ctx.currentPlayer };
    }
    if (IsDraw(G.cells)) {
      return { draw: true };
    }
  },

  ai: {
    enumerate: (G: Props, ctx: Ctx, playerID: PlayerID): AiEnumerate => {
      let moves = [];
      for (let i = 0; i < 9; i++) {
        if (G.cells[i] === null) {
          moves.push({ move: "clickCell", args: [i] });
        }
      }
      return moves;
    },
  },
};

function IsVictory(cells: Cells) {
  return false;
}

function IsDraw(cells: Cells) {
  return false;
}
