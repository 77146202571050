import * as React from "react";
import { createRoot } from "react-dom/client";

import { Local } from "boardgame.io/multiplayer";
import { BoardProps, Client, Lobby } from "boardgame.io/react";
import {
  Hex,
  HexGrid,
  Hexagon,
  Layout,
  Path,
  Pattern,
  Text,
} from "react-hexgrid";
import { Props, TaverniaGame } from "./Game";

function TaverniaBoard({ G, moves, ctx, playerID }: BoardProps<Props>) {
  let team;
  if (playerID == "0") {
    team = <span className="content-0">⭕</span>;
  } else {
    team = <span>❌</span>;
  }

  let winner;
  if (ctx.gameover) {
    if (ctx.gameover.winner) {
      if (ctx.gameover.winner == playerID) {
        winner = <h2>{team} 🎉 Congrats, you won! 🎉</h2>;
      } else {
        winner = <h2>{team} YOU HAVE BEEN DISHONORED</h2>;
      }
    } else {
      winner = <h2>{team} Draw</h2>;
    }
  }

  let title;
  let waitingClass = "";
  if (ctx.currentPlayer == playerID) {
    title = <h2>{team} Your turn!</h2>;
  } else {
    title = (
      <h2>
        <span className="spin">⏳</span> Waiting...
      </h2>
    );
    waitingClass = "waiting";
  }

  let hexagons: JSX.Element[] = [];

  for (let k of Object.keys(G.cells)) {
    let cell = G.cells[k];
    // parse coordinates from key
    let [q, r, s] = k.split(",").map((x) => parseInt(x, 10));

    hexagons.push(
      <Hexagon key={k} q={q} r={r} s={s}>
        <Text>{`${q},${r},${s} id ${cell.tileSpecId}`}</Text>
      </Hexagon>,
    );
  }

  let candidates: {
    [key: string]: boolean;
  } = {};

  let offsets = [
    [0, -1, 1],
    [1, -1, 0],
    [1, 0, -1],
    [0, 1, -1],
    [-1, 1, 0],
    [-1, 0, 1],
  ];

  for (let k of Object.keys(G.cells)) {
    let cell = G.cells[k];
    let [q, r, s] = k.split(",").map((x) => parseInt(x, 10));

    for (let offset of offsets) {
      let q2 = q + offset[0];
      let r2 = r + offset[1];
      let s2 = s + offset[2];
      let candidate = [q2, r2, s2].join(",");
      if (!G.cells[candidate]) {
        candidates[candidate] = true;
      }
    }
  }

  for (let k of Object.keys(candidates)) {
    let cell = candidates[k];
    // parse coordinates from key
    let [q, r, s] = k.split(",").map((x) => parseInt(x, 10));

    hexagons.push(
      <Hexagon key={k} q={q} r={r} s={s}>
        <Text>{`${q},${r},${s} x`}</Text>
      </Hexagon>,
    );
  }

  return (
    <div>
      {title}

      <HexGrid width={1200} height={800} viewBox="-50 -50 200 200">
        {/* Grid with manually inserted hexagons */}
        <Layout
          size={{ x: 10, y: 10 }}
          flat={true}
          spacing={1.02}
          origin={{ x: 0, y: 0 }}
        >
          {hexagons}
        </Layout>
      </HexGrid>

      {winner}
    </div>
  );
}

const appElement = document.getElementById("app");
if (!appElement) {
  throw new Error("No app element found");
}

let backendURL: string;
if (process.env.NODE_ENV == "production") {
  backendURL = "https://tavernia.bearcove.net";
} else {
  backendURL = "http://localhost:8000/backend";
}

// get backend URL from url parameter 'backend' if it's set
let params = new URLSearchParams(window.location.search);
if (params.has("backend")) {
  backendURL = params.get("backend") as string;
}
console.debug(`Using backend URL: ${backendURL}`);

const root = createRoot(appElement);
if (params.has("local")) {
  let App = Client<Props>({
    game: TaverniaGame,
    board: TaverniaBoard,
    multiplayer: Local({}),
  });

  root.render(
    <React.StrictMode>
      <h2>Player 0</h2>
      <App playerID="0" />
      <hr />
      <h2>Player 1</h2>
      <App playerID="1" />
    </React.StrictMode>,
  );
} else {
  root.render(
    <React.StrictMode>
      <Lobby
        gameServer={backendURL}
        lobbyServer={backendURL}
        gameComponents={[{ game: TaverniaGame, board: TaverniaBoard }]}
      />
    </React.StrictMode>,
  );
}
