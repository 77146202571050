// "w" = woods, "l" = lake, "f" = field, "n" = neutral
type Terrain = "w" | "l" | "f" | "n";

export interface TileSpec {
  terrain: {
    // flat-top orientation, starting from the side on the right of the top side,
    // going around clockwise.
    sides: Terrain[];
    center: Terrain;
  };
  hasQuest?: boolean;
  hasCreature?: boolean;
  hasTavern?: boolean;
  id: string;
}

export const ALL_TILES: TileSpec[] = [
  {
    terrain: { sides: ["w", "w", "l", "l", "f", "f"], center: "n" },
    hasTavern: true,
    id: "01",
  },
  {
    terrain: { sides: ["w", "w", "w", "w", "w", "w"], center: "w" },
    id: "02",
  },
  {
    terrain: { sides: ["w", "w", "w", "w", "w", "w"], center: "f" },
    hasCreature: true,
    id: "03",
  },
  {
    terrain: { sides: ["w", "w", "w", "w", "w", "w"], center: "l" },
    hasQuest: true,
    id: "04",
  },
  {
    terrain: { sides: ["f", "f", "w", "w", "w", "w"], center: "w" },
    hasQuest: true,
    id: "05",
  },
  {
    terrain: { sides: ["f", "f", "w", "w", "w", "w"], center: "f" },
    hasCreature: true,
    id: "06",
  },
  {
    terrain: { sides: ["f", "f", "w", "w", "w", "w"], center: "l" },
    id: "07",
  },
  {
    terrain: { sides: ["l", "l", "w", "w", "w", "w"], center: "w" },
    hasQuest: true,
    id: "08",
  },
  {
    terrain: { sides: ["l", "l", "w", "w", "w", "w"], center: "f" },
    hasQuest: true,
    hasCreature: true,
    id: "09",
  },
  {
    terrain: { sides: ["l", "l", "w", "w", "w", "w"], center: "l" },
    hasQuest: true,
    id: "10",
  },
  {
    terrain: { sides: ["f", "f", "f", "f", "f", "f"], center: "w" },
    hasCreature: true,
    id: "11",
  },
  {
    terrain: { sides: ["f", "f", "f", "f", "f", "f"], center: "f" },
    hasQuest: true,
    hasCreature: true,
    id: "12",
  },
  {
    terrain: { sides: ["f", "f", "f", "f", "f", "f"], center: "l" },
    hasQuest: true,
    id: "13",
  },
  {
    terrain: { sides: ["w", "w", "f", "f", "f", "f"], center: "w" },
    hasQuest: true,
    id: "14",
  },
  {
    terrain: { sides: ["w", "w", "f", "f", "f", "f"], center: "f" },
    id: "15",
  },
  {
    terrain: { sides: ["w", "w", "f", "f", "f", "f"], center: "l" },
    hasQuest: true,
    hasCreature: true,
    id: "16",
  },
  {
    terrain: { sides: ["l", "l", "f", "f", "f", "f"], center: "w" },
    hasCreature: true,
    id: "17",
  },
  {
    terrain: { sides: ["l", "l", "f", "f", "f", "f"], center: "f" },
    hasQuest: true,
    id: "18",
  },
  {
    terrain: { sides: ["l", "l", "f", "f", "f", "f"], center: "l" },
    id: "19",
  },
  {
    terrain: { sides: ["l", "l", "l", "l", "l", "l"], center: "w" },
    id: "20",
  },
  {
    terrain: { sides: ["l", "l", "l", "l", "l", "l"], center: "f" },
    hasCreature: true,
    id: "21",
  },
  {
    terrain: { sides: ["l", "l", "l", "l", "l", "l"], center: "l" },
    hasCreature: true,
    id: "22",
  },
  {
    terrain: { sides: ["w", "w", "l", "l", "l", "l"], center: "w" },
    hasCreature: true,
    id: "23",
  },
  {
    terrain: { sides: ["w", "w", "l", "l", "l", "l"], center: "f" },
    hasQuest: true,
    id: "24",
  },
  {
    terrain: { sides: ["w", "w", "l", "l", "l", "l"], center: "l" },
    hasQuest: true,
    hasCreature: true,
    id: "25",
  },
  {
    terrain: { sides: ["f", "f", "l", "l", "l", "l"], center: "w" },
    hasQuest: true,
    id: "26",
  },
  {
    terrain: { sides: ["f", "f", "l", "l", "l", "l"], center: "f" },
    id: "27",
  },
  {
    terrain: { sides: ["f", "f", "l", "l", "l", "l"], center: "l" },
    hasCreature: true,
    id: "28",
  },
  {
    terrain: { sides: ["l", "l", "f", "f", "w", "w"], center: "w" },
    hasCreature: true,
    id: "29",
  },
  {
    terrain: { sides: ["l", "l", "f", "f", "w", "w"], center: "f" },
    hasQuest: true,
    id: "30",
  },
  {
    terrain: { sides: ["l", "l", "f", "f", "w", "w"], center: "l" },
    hasCreature: true,
    id: "31",
  },
  {
    terrain: { sides: ["f", "f", "l", "l", "w", "w"], center: "w" },
    hasQuest: true,
    hasCreature: true,
    id: "32",
  },
  {
    terrain: { sides: ["f", "f", "l", "l", "w", "w"], center: "f" },
    id: "33",
  },
  {
    terrain: { sides: ["f", "f", "l", "l", "w", "w"], center: "l" },
    hasQuest: true,
    hasCreature: true,
    id: "34",
  },
];

export const TAVERN_TILE_ID = "01";

export const ALL_TILES_MAP = (() => {
  let result: {
    [key: string]: TileSpec;
  } = {};
  for (let tile of ALL_TILES) {
    result[tile.id] = tile;
  }
  return result;
})();
